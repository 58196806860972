
import Vue from 'vue'

export default Vue.extend({
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isYoutubeLink(): boolean {
      return this.videoUrl.includes('https://www.youtube.com')
    },
  },
  updated() {
    if (this.show) {
      this.$nextTick(() => {
        ;(this.$el as HTMLElement).focus()
      })
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
  },
})
