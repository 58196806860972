
import Vue from 'vue'

export default Vue.extend({
  name: 'CommonSalesDialog',
  props: {
    dialogType: String,
    googleCalendarLink: String,
  },
  data() {
    return {
      toggleTypeformDialog: () => {},
    }
  },

  methods: {
    talkToSalesClicked(dialogType: string): void {
      dialogType = dialogType || this.dialogType
      switch (dialogType) {
        case 'Google':
          window.open(
            this.googleCalendarLink
              ? this.googleCalendarLink
              : `https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2avnFvzIl55yMwH7LqoCueO-Z0C8ZIg_0BJG6meGXBvxyz8WxCNQLVmKA12InHpWwu1TwbXpGT`,
            '_blank'
          )
          break
        case 'Typeform':
          this.toggleTypeformDialog()
          break
      }

      this.$emit('talkToSalesClicked')
    },
  },
})
